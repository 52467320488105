<template>
  <div class="top-container">
    <div class="filter-controls row">
      <div class="col-md-6 col-sm-12">
        <h5>{{ $t('Domain') }}</h5>
        <p>{{ $t('message.select_domain_access_logs_help') }}</p>
        <div class="radio-buttons-wrapper">
          <label>
            <input type="radio" value="cname" v-model="selectedDropdownValue" @change="handleRadioChange">
            {{ $t('Cname') }}
          </label>
          <label>
            <input type="radio" value="domain" v-model="selectedDropdownValue" @change="handleRadioChange">
            {{ $t('Domain') }}
          </label>
        </div>

        <div v-if="selectedDropdownValue === 'domain'" class="form-group form-row">
          <label class="col-form-label col-sm-3">{{ $t('Domain') }}</label>
          <div class="col-sm-8">
            <multiselect v-model="selectedDomains"
                         :options="domainsOptions"
                         label="name"
                         track-by="pk"
                         :loading="domainData.loading"
                         :multiple="true"
                         :close-on-select="false"
                         @search-change="searchDomain"
                         @input="multiselectClickHandler('domains')">
              <template slot="afterList">
                <span class="load-more" @click="$emit('loadMoreDomain')" v-if="domainData.hasNext">{{
                    $t('load_more')
                  }}</span>
              </template>
              <span slot="noResult">{{ $t('message.domain_not_found') }}</span>
            </multiselect>
          </div>
        </div>
        <div v-if="selectedDropdownValue === 'domain'" class="form-group form-row">
          <label class="col-form-label col-sm-3">{{ $t('domain.ShadowDomain') }}</label>
          <div class="col-sm-8">
            <multiselect v-model="selectedShadowDomains"
                         :options="shadowDomainsOptions"
                         label="name"
                         track-by="pk"
                         :loading="shadowData.loading"
                         :multiple="true"
                         :close-on-select="false"
                         @search-change="searchShadowDomain"
                         @input="multiselectClickHandler('shadowDomains')">
              <template slot="afterList">
                <span class="load-more" @click="$emit('loadMoreShadowDomain')" v-if="shadowData.hasNext">{{
                    $t('load_more')
                  }}</span>
              </template>
              <span slot="noResult">{{ $t('message.domain_not_found') }}</span>
            </multiselect>
          </div>
        </div>
        <div class="date-picker-container">
          <v-date-picker v-model="viewDate" :min-date="minDate" :max-date="new Date()" :popover="{ visibility: 'focus' }">
            <template v-slot="{ inputValue, inputEvents }">
              <CInput
                  :horizontal="{ label: 'col-sm-3', input: 'col-sm-3'}"
                  :label="$t('date')"
                  :id="viewDateId"
                  :value="inputValue"
                  v-on="inputEvents"
              ></CInput>
            </template>
          </v-date-picker>
        </div>
      </div>

      <div class="col-md-6 col-sm-12">
        <h5>{{ $t('Filter') }}</h5>
        <p>{{ $t('message.filter_by_access_logs_help') }}</p>

        <CInput
            :horizontal="{ label: 'col-sm-3', input: 'col-sm-6'}"
            :label="$t('Remote_Address')"
            :placeholder="$t('helps.ip_address_placeholder')"
            v-model="filterIpAddress"
        ></CInput>

        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">{{ $t('status') }}</label>
          <div class="col-sm-6">
            <multiselect v-model="filterHttpStatusResponse"
                         :options="statusResponseOptions"
                         track-by="value"
                         label="name"
                         :close-on-select="true"></multiselect>
          </div>
        </div>

        <div class="form-group form-row" v-if="filterHttpStatusResponse.value === 'CUSTOM'">
          <label class="col-form-label col-sm-3">{{ $t('status_code') }}</label>
          <div class="col-sm-6">
            <multiselect v-model="selectCustomStatusCodes"
                         :options="httpStatusCodeOptions"
                         :multiple="true"
                         :close-on-select="false"
                         group-values="codes"
                         group-label="group"
                         :group-select="true"
                         track-by="value"
                         label="name"></multiselect>
          </div>
        </div>
        
        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">{{ $t('traffic.RequestTime') }}</label>
          <div class="col-sm-6">
            <OperatorValueField @change="handleRequestTimeUpdate"/>
          </div>
        </div>
        
        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">{{ $t('traffic.BodyBytesSent') }}</label>
          <div class="col-sm-6">
            <OperatorValueField @change="handleBodyBytesSentUpdate"/>
          </div>
        </div>
        
        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">{{ $t('UpstreamCacheStatus') }}</label>
          <div class="col-sm-6">
            <multiselect v-model="filterCache"
                         :options="['-', 'HIT', 'BYPASS','MISS']"
                         :close-on-select="true"></multiselect>
          </div>
        </div>

        <div class="form-group form-row">
          <label class="col-form-label col-sm-3">{{ $t('LimitResult') }}</label>
          <div class="col-sm-6">
            <multiselect v-model="filterLimit"
                         :options="[100, 250, 500]"
                         :close-on-select="true"></multiselect>
          </div>
        </div>
      </div>
    </div>

    <div class="button-container form-row">
      <CButton
          color="secondary"
          :disabled="isLoading"
          :aria-disabled="isLoading"
          @click="handleDownloadLogsClicked">
        <CIcon name="cil-cloud-download" />
        {{ $t('DownloadLogs') }}
      </CButton>
      <CButton
          color="primary"
          :disabled="isLoading"
          :aria-disabled="isLoading"
          @click="handleFilterClicked">
        <CIcon name="cil-filter" />
        {{ $t('Filter') }}
      </CButton>
    </div>

    <CDataTable
        :items="accesslogData"
        :loading="isLoading"
        pagination
    >
    </CDataTable>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.top-container {
  margin-top: 6px;
  padding: 25px;
}
.radio-buttons-wrapper {
  display: flex;
  margin-bottom: 10px;
}
.radio-buttons-wrapper label {
  margin-right: 10px;
}
.filter-controls .multiselect__content .load-more {
  font-size: 0.9em;
  margin-left: 12px;
  color: #39f;
  cursor: pointer;
}

.filter-controls .multiselect__content .load-more:hover {
  text-decoration: underline;
}

.button-container {
  margin-left: 20px;
  margin-bottom: 20px;
}

.button-container button:first-child {
  margin-right: 10px;
}

.date-picker-container {
  margin-top: 6px;
}
</style>

<script>
import Multiselect from 'vue-multiselect';
import {format, subDays} from 'date-fns';
import {isEmpty} from 'lodash';

import axios from "@/plugins/axios.js";
import OperatorValueField, {VALUE_DEFAULT} from '@/views/domain/charts/OperatorValueField';

const STATUS_CODES = [
  {
    group: '1xx - Informational',
    codes: [
      {name: '100 - Continue', value: 100},
      {name: '101 - Switching Protocols', value: 101}
    ]
  },
  {
    group: '2xx - Successful',
    codes: [
      {name: '200 - OK', value: 200},
      {name: '201 - Created', value: 201},
      {name: '202 - Accepted', value: 202},
      {name: '203 - Non-Authoritative Information', value: 203},
      {name: '204 - No Content', value: 204},
      {name: '205 - Reset Content', value: 205},
      {name: '206 - Partial Content', value: 206}
    ]
  },
  {
    group: '3xx - Redirection',
    codes: [
      {name: '300 - Multiple Choices', value: 300},
      {name: '301 - Moved Permanently', value: 301},
      {name: '302 - Found', value: 302},
      {name: '303 - See Other', value: 303},
      {name: '304 - Not Modified', value: 304},
      {name: '305 - Use Proxy', value: 305},
      {name: '306', value: 306},
      {name: '307 - Temporary Redirect', value: 307}
    ]
  },
  {
    group: '4xx - Client Error',
    codes: [
      {name: '400 - Bad Request', value: 400},
      {name: '401 - Unauthorized', value: 401},
      {name: '402 - Payment Required', value: 402},
      {name: '403 - Forbidden', value: 403},
      {name: '404 - Not Found', value: 404},
      {name: '405 - Method Not Allowed', value: 405},
      {name: '406 - Not Acceptable', value: 406},
      {name: '407 - Proxy Authentication Required', value: 407},
      {name: '408 - Request Timeout', value: 408},
      {name: '409 - Conflict', value: 409},
      {name: '410 - Gone', value: 410},
      {name: '411 - Length Required', value: 411},
      {name: '412 - Precondition Failed', value: 412},
      {name: '413 - Payload Too Large', value: 413},
      {name: '414 - URI Too Long', value: 414},
      {name: '415 - Unsupported Media Type', value: 415},
      {name: '416 - Range Not Satisfiable', value: 416},
      {name: '417 - Expectation Failed', value: 417},
      {name: '418 - I\'m a teapot', value: 418},
      {name: '426 - Upgrade Required', value: 426}
    ]
  },
  {
    group: '5xx - Server Error',
    codes: [
      {name: '500 - Internal Server Error', value: 500},
      {name: '501 - Not Implemented', value: 501},
      {name: '502 - Bad Gateway', value: 502},
      {name: '503 - Service Unavailable', value: 503},
      {name: '504 - Gateway Time-out', value: 504},
      {name: '505 - HTTP Version Not Supported', value: 505}
    ]
  }
];

export default {
  components: {OperatorValueField, Multiselect},
  props: {
    hover: Boolean,
    striped: Boolean,
    bordered: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean,
    domainData: Object,
    shadowData: Object,
    searchDomain: Function,
    searchShadowDomain: Function,   
  },
  mounted() {
    this.selectedDropdownValue = 'cname';
  },
  computed: {
    domainsOptions: function () {
      return Object.freeze(this.domainData.domains)
    },
    shadowDomainsOptions: function () {
      return Object.freeze(this.shadowData.shadows)
    }
  },
  data() {
    return {
      viewDateId: "viewDateId",
      accesslogData: [],
      selectedDomains: [],
      selectedShadowDomains: [],
      viewDate: new Date(),
      minDate: subDays(new Date(), 30),
      isLoading: false,
      filterLimit: 100,
      filterIpAddress: '',
      filterHttpStatusResponse: {
        name: '-',
        value: '-'
      },
      filterCache: '-',
      statusResponseOptions: [
        {
          name: '-',
          value: '-'
        },
        {
          name: 'NORMAL (2xx, 3xx)',
          value: 'NORMAL'
        },
        {
          name: 'ERROR (4xx, 5xx)',
          value: 'ERROR'
        },
        {
          name: 'CUSTOM',
          value: 'CUSTOM'
        }
      ],
      selectCustomStatusCodes: [],
      httpStatusCodeOptions: STATUS_CODES,
      requestTimeOperatorValue: VALUE_DEFAULT,
      bodyBytesSentOperatorValue: VALUE_DEFAULT,
      selectedDropdownValue:'',
    };
  },
  methods: {
    multiselectClickHandler(type) {
      const selectedDomains = this.selectedDomains.map((domain) => domain.name);
      const domainsTotalCharacters = selectedDomains.reduce((total, domain) => total + domain.length, 0);
      const selectedshadowdomain=this.selectedShadowDomains.map((domain) => domain.name);
      const shadowsTotalCharacters = selectedshadowdomain.reduce((total, shadow) => total + shadow.length, 0);
      const combinedTotalCharacters = domainsTotalCharacters + shadowsTotalCharacters;
      if (combinedTotalCharacters >= 1800)  {
        this.flash(this.$t('message.DomainCharacterLimitError'), "error", { timeout: 5000 });
        if(type == 'domains'){
          this.selectedDomains.pop();
        }else if(type == 'shadowDomains'){
          this.selectedShadowDomains.pop();
        }
     }},
    handleRadioChange() {
      if (this.selectedDropdownValue) {
        if(this.selectedDropdownValue === "domain"){
          return this.selectedDropdownValue
        }
      }
    },
    async fetchData() {
      this.isLoading = true;
      const commondata = this.getCommonData();
      if (this.selectedDropdownValue === 'cname') {
        commondata.domains = [];
        commondata.shadows = [];
      }
      const domainsTotalCharacters = commondata.domains.reduce((total, domain) => total + domain.length, 0);
      const shadowsTotalCharacters = commondata.shadows.reduce((total, shadow) => total + shadow.length, 0);
      const combinedTotalCharacters = domainsTotalCharacters + shadowsTotalCharacters;
      if (combinedTotalCharacters >= 1800)  {
        this.flash(this.$t('message.DomainCharacterLimitError'), "error", { timeout: 5000 });
        this.isLoading = false;
        return;
      }
      let response;
      try {
        response = await axios({
          method: "POST",
          url: `domain/traffic_stats/access_logs/`,
          data: commondata
        });
      } catch (errors) {
        this.isLoading = false;
        errors.forEach((message) => {
          this.flash(message, 'error', {"timeout": 5000});
        });
        return;
      }

      this.isLoading = false;
      this.accesslogData = response.data;
    },
    getCommonData() {
      const domains = [];
      const shadows = [];
      for (let d of this.selectedDomains) {
        domains.push(d.name);
      }
      for (let d of this.selectedShadowDomains) {
        shadows.push(d.name);
      }

      const statusCodes = [];
      if (this.filterHttpStatusResponse.value === 'CUSTOM') {
        for (let s of this.selectCustomStatusCodes) {
          statusCodes.push(s.value);
        }
      }
      
      const requestTime = isEmpty(this.requestTimeOperatorValue.value.trim()) ? null : this.requestTimeOperatorValue;
      const bodyBytesSent = isEmpty(this.bodyBytesSentOperatorValue.value.trim()) ? null : this.bodyBytesSentOperatorValue;

      return {
        date: this.viewDate ? format(this.viewDate, 'yyyy-MM-dd') : null,
        ip_address: this.filterIpAddress,
        http_status_response: this.filterHttpStatusResponse.value,
        status_codes: statusCodes,
        cache: this.filterCache,
        request_time: requestTime,
        body_bytes_sent: bodyBytesSent,
        domains: domains,
        shadows: shadows,
        limit: this.filterLimit,
        cname : this.$store.state.cname
      };
    },
    async downloadLogs() {
      this.isLoading = true;

      const commonData = this.getCommonData();
      let response;
      try {
        response = await axios({
          method: "POST",
          url: `domain/traffic_stats/download_access_logs/`,
          responseType: 'blob',
          data: commonData
        });
      } catch (errors) {
        this.isLoading = false;
        errors.forEach((message) => {
          this.flash(message, 'error', {"timeout": 5000});
        });
        return;
      }

      this.isLoading = false;

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;

      let filename = `access_logs_${commonData.date}.csv`;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    },
    handleFilterClicked() {
      if (this.filterHttpStatusResponse.value === 'CUSTOM' && !this.selectCustomStatusCodes.length) {
        this.flash(this.$t('message.select_status_codes'), 'error', {"timeout": 5000});
        return;
      }

      this.fetchData();
    },
    handleDownloadLogsClicked() {
      if ((!this.selectedDomains || !this.selectedDomains.length) && (!this.selectedShadowDomains || !this.selectedShadowDomains.length)) {
        this.flash(this.$t('message.select_domain'), 'error', {"timeout": 5000});
        return;
      }
      if (this.filterHttpStatusResponse.value === 'CUSTOM' && !this.selectCustomStatusCodes.length) {
        this.flash(this.$t('message.select_status_codes'), 'error', {"timeout": 5000});
        return;
      }

      this.downloadLogs();
    },
    handleRequestTimeUpdate(data) {
      this.requestTimeOperatorValue = data;
    },
    handleBodyBytesSentUpdate(data) {
      this.bodyBytesSentOperatorValue = data;
    },
  }
}
</script>
