<script>
import {Bar, mixins} from 'vue-chartjs';

const {reactiveProp} = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
  props: ['chartData', 'options'],
  watch: {
    options: function () {
      // options is not reactive, so for re-render here.
      this.renderChart(this.chartData, this.options);
    }
  }
}
</script>
