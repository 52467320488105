<template>
  <div>
    <CCard>
      <CCardBody>
        <CTabs>
          <CTab :title="$t('overview')" active>
            <OverviewTraffic :domainData="domainData" @loadMoreDomain="loadMoreDomains" :searchDomain="searchDomain"/>
          </CTab>
          <CTab :title="$t('logs')">
            <AccesslogTraffic :domainData="domainData" :shadowData="shadowData" @loadMoreDomain="loadMoreDomains" @loadMoreShadowDomain="loadMoreShadowDomain" :searchDomain="searchDomain" :searchShadowDomain="searchShadowDomain"/>
          </CTab>
        </CTabs>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import axios from "@/plugins/axios.js";
import {debounce, isEmpty} from 'lodash';

import OverviewTraffic from './charts/OverviewTraffic';
import AccesslogTraffic from './charts/AccesslogTraffic';

const DOMAINS_PER_PAGE = 20;
const MILLISECONDS_DELAY = 500;

export default {
  components: {AccesslogTraffic, OverviewTraffic},
  data() {
    return {
      domainData: {domains: [], hasNext: false, loading: false},
      shadowData: {shadows: [], hasNext: false, loading: false},
      domainsPage: 1,
      shadowDomainsPage: 1,
      storedDomainSearch: null,
      storedShadowDomainSearch: null,
      delayedFetchDomains: null,
      delayedFetchShadowDomains: null
    };
  },
  mounted() {
    this.delayedFetchDomains = debounce(this.fetchDomains, MILLISECONDS_DELAY);
    this.delayedFetchShadowDomains = debounce(this.fetchShadowDomain, MILLISECONDS_DELAY);

    this.fetchDomains({ page: 1 });
    this.fetchShadowDomain({page: 1});
  },
  methods: {
    async fetchDomains(options) {
      const filterParams = {
        page: options.page,
        perpage: DOMAINS_PER_PAGE
      };
      if (options.search) {
        filterParams.search = options.search;
      }

      this.domainData = Object.freeze({...this.domainData, loading: true});

      const response = await axios
          .get('domain/', {
            params: filterParams
          });

      const newDomainData = {...this.domainData};
      if (options.page === 1) {
        newDomainData.domains = [];
      }
      newDomainData.domains = [...newDomainData.domains, ...response.data.results];
      newDomainData.hasNext = Boolean(response.data.next);
      newDomainData.loading = false;

      this.domainData = Object.freeze(newDomainData);
    },
    async fetchShadowDomain(options) {
      const filterParams = {
        page: options.page,
        perpage: DOMAINS_PER_PAGE
      };
      if (options.search) {
        filterParams.search = options.search;
      }

      this.shadowData = Object.freeze({...this.shadowData, loading: true});
      const response = await axios
          .get('domain/shadow/', {
            params: filterParams
          });

      const newDomainData = {...this.shadowData};
      if (options.page === 1) {
        newDomainData.shadows = [];
      }
      newDomainData.shadows = [...newDomainData.shadows, ...response.data.results];
      newDomainData.hasNext = Boolean(response.data.next);
      newDomainData.loading = false;

      this.shadowData = Object.freeze(newDomainData);
    },
    async loadMoreDomains() {
      const options = {page: ++this.domainsPage};
      if (this.storedDomainSearch) {
        options.search = this.storedDomainSearch;
      }

      await this.fetchDomains(options);
    },
    async loadMoreShadowDomain() {
      const options = {page: ++this.shadowDomainsPage};
      if (this.storedShadowDomainSearch) {
        options.search = this.storedShadowDomainSearch;
      }
      await this.fetchShadowDomain(options);
    },
    async searchDomain(searchQuery) {
      this.domainsPage = 1;
      this.storedDomainSearch = isEmpty(searchQuery) ? null : searchQuery;

      // trigger loading even though the ajax is delayed to give immediate feedback to the user
      this.domainData = Object.freeze({...this.domainData, loading: true});

      this.delayedFetchDomains({page: this.domainsPage, search: this.storedDomainSearch});
    },
    async searchShadowDomain(searchQuery) {
      this.shadowDomainPage = 1;
      this.storedShadowDomainSearch = isEmpty(searchQuery) ? null : searchQuery;
      // trigger loading even though the ajax is delayed to give immediate feedback to the user
      this.shadowData = Object.freeze({...this.shadowData, loading: true});

      this.delayedFetchShadowDomains({page: this.shadowDomainsPage, search: this.storedShadowDomainSearch});
    }
  }
};
</script>
