<template>
  <div class="top-container">
    <div class="options-container">
      <CSelect :label="$t('Select') + ' ' + $t('CNAME')" class="select-cname option-item" :value="userCname" :disabled="isLoading"
        @change="onChangeSubscription" :options="subscriptions">
      </CSelect>

      <div class="date-picker-container option-item">
        <slideout-panel></slideout-panel>
        <p class="time-range-label">{{ $t('message.configure_time_range') }}</p>
        <div :class="{ 'time-range-display': true, 'loading': isLoading }">
          <div class="time-range-control" @click="showPanel">
            <CIcon name="cil-clock" style="margin-right: 6px;" />
            <div class="text-display">
              <span class="adjustment-type">{{ timeAdjustmentTypeDisplay }}</span>
              <span class="time-range">{{ timeRangeDisplay }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr>

    <div class="top-overview-container">
      <header>
        <h5>{{ $t('overview') }}</h5>
        <p style="margin-bottom: 6px">{{ $t('message.select_overview_help') }}</p>
      </header>
      <div class="form-row">
        <div class="col-sm-4">
          <multiselect v-model="selectedDomain" :options="graphDomainSelectorOptions" label="name" track-by="pk"
            :disabled="isLoading" :loading="domainData.loading" :close-on-select="true" @search-change="searchDomain">
            <template slot="option" slot-scope="props">
              <span v-if="props.option.pk !== allToken" class="option__title">{{ props.option.name }}</span>
              <span v-if="props.option.pk === allToken" class="option__title all">
                <span>{{ $t('domain.AllDomains') }}</span>
                <span class="description">{{ $t('message.ViewAllTrafficCname', [userCname]) }}</span>
              </span>
            </template>
            <template slot="afterList">
              <span class="load-more" @click="$emit('loadMoreDomain')" v-if="domainData.hasNext">{{
                $t('load_more')
              }}</span>
            </template>
            <span slot="noResult">{{ $t('message.domain_not_found') }}</span>
          </multiselect>
        </div>
      </div>

      <div class="overview-container">
        <div :class="{ 'spinner-container': true, 'opacity-0': !isLoading }">
          <CSpinner />
          <span>{{ $t('message.updating_graph') }}</span>
        </div>
        <div class="chart-container">
          <BarChart :height="150" :chart-data="trafficByHourData" :options="trafficByHourOptions" />
        </div>
        <div class="chart-container" style="margin-top: 15px;">
          <BarChart :height="150" :chart-data="requestByHourData" :options="requestsByHourOptions" />
        </div>
      </div>

      <hr style="margin-top: 50px;">

      <div>
        <header>
          <h5>{{ $t('message.BreakdownPerDomain') }}</h5>
          <p>{{ $t('message.TotalTrafficAndRequestsForEachDomain') }}</p>
        </header>

        <OverviewDomainsTable :domains="domainData.domains" :traffic-data="trafficDomainData" ref="overviewDomain" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.total-graph {
  display: block;
  margin-top: 10px;
  font-weight: bold;
}

.top-container {
  padding: 25px;
}

.options-container {
  display: flex;
  flex-direction: row;
}

.option-item {
  padding: 10px;
}

.top-overview-container,
.overview-container {
  margin-top: 20px;
}

header {
  display: flex;
  flex-direction: column;
}

.date-picker-container {
  display: inline-block;
  /* margin-top: 6px; */
}

.top-overview-container .multiselect__content .load-more {
  font-size: 0.9em;
  margin-left: 12px;
  color: #39f;
  cursor: pointer;
}

.top-overview-container .multiselect__content .load-more:hover {
  text-decoration: underline;
}

.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-left: 10px;
}

.opacity-0 {
  opacity: 0;
}

.spinner-container span {
  margin-left: 10px;
}

.time-range-label {
  margin-bottom: 5px;
}

.time-range-display.loading .time-range-control {
  color: gray;
  background-color: #fbfbfb;
}

.time-range-display.loading .time-range-control {
  cursor: not-allowed;
}

.time-range-display .time-range-control {
  display: inline-flex;
  align-items: center;
  color: #321fdb;
  cursor: pointer;
  font-weight: 500;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.time-range-display .time-range-control:hover {
  background-color: #fbfbfb;
}

.text-display {
  display: flex;
  flex-direction: column;
}

.time-range-display .time-range {
  font-size: 0.9em;
  font-weight: normal;
  color: gray;
}

.option__title.all span {
  display: block;
}

.option__title.all span:first-child {
  font-weight: 500;
}

.option__title.all .description {
  font-size: 0.9em;
  margin-top: 5px;
}

.select-cname {
  display: inline-block;
}
</style>

<style>
.select-cname label {
  margin-bottom: 5px;
}

.select-cname select {
  height: 61px;
}
</style>

<script>
import { isEmpty } from 'lodash';
import {
  format,
  addSeconds,
  addMinutes,
  subSeconds,
  subMinutes,
  parseISO,
  differenceInMinutes
} from 'date-fns';
import numeral from 'numeral';
import Multiselect from 'vue-multiselect';

import axios from '@/plugins/axios';
import BarChart from './BarChart';
import { ADJUSTMENT_TYPE, TIME_ADJUSTMENTS, TIME_ADJUSTMENTS_TRANSLATIONS } from './TimeRangePicker';
import OverviewDomainsTable from "@/views/domain/charts/OverviewDomainsTable";
import { subscriptionPlan } from "@/utilities/api";
import { UNCOVERED_NETWORK_TRAFFIC } from "@/utilities/constants";

const SHANGHAI_UTC_OFFSET_MINUTES = 480;

// We do a two-step conversion to get to Asia/Shanghai.
// First, we convert the user's time to UTC.
// Second, from UTC convert to Asia/Shanghai.
// We do it this way to make our base UTC.
// Important! We do not change the date object's timezone information. Even though we convert the "time", the timezone
// will still be the user's timezone (system timezone)
function toAliyunTimezone(date, adjustment) {
  if (adjustment == ADJUSTMENT_TYPE.RELATIVE) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: "Asia/Singapore" }));
  }
  return new Date(typeof date === "string" ? new Date(date) : date);
}

function fromAliyunTimezone(date) {
  const offsetMinutes = new Date().getTimezoneOffset();

  const aliyunTime = subMinutes(date, SHANGHAI_UTC_OFFSET_MINUTES);

  return subMinutes(aliyunTime, offsetMinutes);
}

function parseISOtoSystemTime(date) {
  return fromAliyunTimezone(parseISO(date));
}

function transparentize(color, opacity = 0.5) {
  const alpha = 1 - opacity;

  return Color(color).alpha(alpha).rgbString();
}


function addUncoveredNetworkTraffic(total) {
  return total + (total * (UNCOVERED_NETWORK_TRAFFIC / 100));
}

export default {
  props: ['domainData', 'searchDomain'],
  components: {
    OverviewDomainsTable,
    BarChart,
    Multiselect
  },
  mounted() {
    this.$store.commit('resetTimeRangePicker');
    this.init();
  },
  watch: {
    selectedDomain: function () {
      if (this.selectedDomain && (this.selectedDomain.pk === this.allToken)) {
        this.onApplyTimeRange(this.userCname);
      } else {
        this.onApplyTimeRange();
      }
    }
  },
  data() {
    const allToken = '__all__';

    return {
      selectedDomain: {
        pk: allToken,
        name: this.$t('domain.AllDomains')
      },
      trafficDomainData: [],
      trafficCnameData: [],
      isLoading: false,
      timeRangePanel: null,
      allToken: allToken,
      seriesInfo: {
        interval: 15,
        time_unit: 's'
      },
      subscriptions: []
    }
  },
  computed: {
    userCname: {
      get() {
        let cname = this.$store.state.cname;
        if (cname) {
          cname = cname.replace(/..[a-z][0-9]{0,3}.[a-z]{0,3}$/, '');
          return cname;
        }
        return null;
      },
      set(value) {
        this.$store.state.cname = value;
      }
    },
    timeRange: {
      get() {
        return this.$store.state.timeRangePicker.range;
      },
      set(value) {
        this.$store.state.timeRangePicker.range = value;
      }
    },
    timeAdjustment: {
      get() {
        return this.$store.state.timeRangePicker.adjustment;
      },
      set(value) {
        this.$store.state.timeRangePicker.adjustment = value;
      }
    },
    graphDomainSelectorOptions: function () {
      if (!this.userCname)
        return this.domainData.domains;

      return [{ pk: this.allToken, name: this.$t('all') }, ...this.domainData.domains];
    },
    timeAdjustmentTypeDisplay: function () {
      if (!this.timeAdjustment)
        return null;

      const adjustmentType = this.timeAdjustment[0];
      if (adjustmentType === ADJUSTMENT_TYPE.CUSTOM)
        return this.$t('Custom');

      let adjustmentTypeTranslations = 'time_picker.Relative';
      if (adjustmentType === ADJUSTMENT_TYPE.TIME_FRAME)
        adjustmentTypeTranslations = 'time_picker.TimeFrame';

      const adjustment = this.timeAdjustment[1];

      return `${this.$t(TIME_ADJUSTMENTS_TRANSLATIONS[adjustment])} (${this.$t(adjustmentTypeTranslations)})`;
    },
    timeRangeDisplay: function () {
      const timeFormat = 'MMM d, yyyy, HH:mm:ss';

      return format(this.timeRange.start, timeFormat) + ' ~ ' + format(this.timeRange.end, timeFormat);
    },
    timeUnit: function () {
      const adjustmentType = this.timeAdjustment[0];
      if (adjustmentType === ADJUSTMENT_TYPE.CUSTOM) {
        const diff = differenceInMinutes(this.timeRange.end, this.timeRange.start);

        const isLessThanMinute = diff <= 1;
        const isLessThanFourHours = diff <= 5760;

        if (isLessThanMinute)
          return 'second';
        else if (isLessThanFourHours)
          return 'minute';

        return 'day';
      }

      const shouldBeSecondTimeUnits = [
        TIME_ADJUSTMENTS.MIN_1,
        TIME_ADJUSTMENTS.MIN_5,
        TIME_ADJUSTMENTS.MIN_15
      ];

      const adjustment = this.timeAdjustment[1];
      if (shouldBeSecondTimeUnits.includes(adjustment)) {
        return 'second';
      } else if (adjustment === TIME_ADJUSTMENTS.DAY_1) {
        return 'day';
      } else if (adjustment === TIME_ADJUSTMENTS.WEEK_1) {
        return 'day';
      }

      return 'minute';
    },
    trafficByHourOptions: function () {
      let title = this.$t('traffic.Traffic');
      if (this.selectedDomain)
        title += ': ' + this.selectedDomain.name;

      return {
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        title: {
          display: true,
          text: title
        },
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              unit: this.timeUnit
            },
            distribution: 'linear',
            offset: true,
            ticks: {
              source: 'data',
              autoSkip: true,
              autoSkipPadding: 75,
              maxRotation: 0,
            }
          }],
          yAxes: [{
            ticks: {
              source: 'data',
              autoSkip: true,
              autoSkipPadding: 75,
              callback: function (value) {
                return numeral(value).format('0.00 b');
              }
            }
          }]
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              let label = data.datasets[tooltipItem.datasetIndex].label || '';
              if (label) {
                label += ': ';
              }
              label += numeral(tooltipItem.yLabel).format('0.00 b');

              return label;
            }
          }
        }
      }
    },
    requestsByHourOptions: function () {
      let title = this.$t('traffic.Requests');
      if (this.selectedDomain)
        title += ': ' + this.selectedDomain.name;

      return {
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        title: {
          display: true,
          text: title
        },
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              unit: this.timeUnit
            },
            distribution: 'linear',
            offset: true,
            ticks: {
              source: 'data',
              autoSkip: true,
              autoSkipPadding: 75,
              maxRotation: 0,
            }
          }],
          yAxes: [{
            ticks: {
              source: 'data',
              autoSkip: true,
              autoSkipPadding: 75,
              callback: function (value) {
                return numeral(value).format('0 a');
              }
            }
          }]
        }
      };
    },
    chartTrafficDataSource: function () {
      if (this.isViewByCname)
        return this.trafficCnameData;

      return this.trafficDomainData;
    },
    trafficByHourData: function () {
      if (isEmpty(this.chartTrafficDataSource) || !this.timeRange || !this.selectedDomainViewBy || !this.chartTrafficDataSource[this.selectedDomainViewBy]) {
        return {
          datasets: []
        };
      }

      const trafficData = this.chartTrafficDataSource[this.selectedDomainViewBy];

      let data = [];
      trafficData.forEach(traffic => {
        const trafficDate = parseISOtoSystemTime(traffic.second);
        const totalTraffic = traffic.total_traffic;
        const chart_data = data.find(item => item.t.getTime() == trafficDate.getTime());
        if (chart_data != undefined) {
          chart_data.y += addUncoveredNetworkTraffic(totalTraffic);
        } else {
          data.push({ t: trafficDate, y: addUncoveredNetworkTraffic(totalTraffic) });
        }
      });

      const dataset = {
        label: this.selectedDomainViewBy,
        data: [...data],
        borderColor: "rgb(54, 162, 235)",
        backgroundColor: transparentize("rgb(54, 162, 235)"),
      };

      return { datasets: [dataset] };
    },
    requestByHourData: function () {
      if (isEmpty(this.chartTrafficDataSource) || !this.timeRange || !this.selectedDomainViewBy || !this.chartTrafficDataSource[this.selectedDomainViewBy]) {
        return {
          datasets: []
        };
      };

      const trafficData = this.chartTrafficDataSource[this.selectedDomainViewBy];

      const data = [];
      trafficData.forEach(traffic => {
        const trafficDate = parseISOtoSystemTime(traffic.second);

        const chart_data = data.find(item => item.t.getTime() == trafficDate.getTime());
        if (chart_data != undefined) {
          chart_data.y += traffic.total_request;
        } else {
          data.push({ t: trafficDate, y: traffic.total_request });
        }
      });

      const dataset = {
        label: this.selectedDomainViewBy,
        data: [...data],
        borderColor: "rgb(75, 192, 192)",
        backgroundColor: transparentize("rgb(75, 192, 192)"),
      };

      return { datasets: [dataset] }
    },
    isViewByCname: function () {
      if (!this.selectedDomain)
        return false;

      return this.selectedDomain.pk === this.allToken;
    },
    selectedDomainViewBy: function () {
      if (!this.selectedDomain)
        return null;

      if (this.isViewByCname)
        return this.userCname;

      return this.selectedDomain.name;
    }
  },
  methods: {
    async init() {
      await this.fetchCname();
    },
    async onChangeSubscription($event) {
      const subscription = this.subscriptions.find(item => item.value === $event.target.value);
      this.userCname = subscription.cname + "." + subscription.czone;
      // fetch "all domains" data
      await this.onApplyTimeRange(this.userCname);
      // fetch per domain data
      // await this.onApplyTimeRange();
    },
    async fetchCname() {
      let response;
      try {
        response = await subscriptionPlan();
      } catch (errors) {
        errors.forEach((message) => {
          this.flash(message, 'error', { "timeout": 5000 });
        });
        return;
      }
      let subscriptions = response.data;
      if (!subscriptions.length) {
        return;
      }

      if (!this.userCname) {
        this.userCname = subscriptions[0].cname + "." + subscriptions[0].czone;
      }
      this.subscriptions = Object.freeze(subscriptions.map(item => { return { cname: item.cname, czone: item.czone, value: item.cname, label: item.cname } }));

      // fetch "all domains" data
      await this.onApplyTimeRange(this.userCname);
      // fetch per domain data
      // await this.onApplyTimeRange();
    },
    async onApplyTimeRange(getByCname = null, page = 1) {
      this.isLoading = true;

      let response;
      try {
        response = await axios({
          url: `domain/traffic_stats/get_overview_data/`,
          params: {
            get_by_cname: getByCname,
            start: format(toAliyunTimezone(this.timeRange.start), 'yyyy-MM-dd HH:mm:ss'),
            end: format(toAliyunTimezone(this.timeRange.end), 'yyyy-MM-dd HH:mm:ss'),
            page: page
          },
          timeout: 3000000,
        });
      } catch (errors) {
        this.isLoading = false;

        errors.forEach((message) => {
          this.flash(message, 'error', { "timeout": 5000 });
        });
        return;
      }

      this.seriesInfo = Object.freeze(response.data.series_info);

      const isPagedRequest = page > 1;

      const trafficData = isPagedRequest && !getByCname ? { ...this.trafficDomainData } : {};
      const trafficDomainData = {};

      // domain traffic are returned from the api either under domains field or logs field
      const dataArray = getByCname ? response.data.domains : response.data.logs;

      // Store shadow domains from the current API response and sort it based on wildcard
      const shadowDomains = Object.entries(dataArray.reduce((acc, item) => {
        if (item.shadow && item.shadow !== '') {
            item.shadow.split(',').forEach(shadowDomain => acc[shadowDomain.trim()] = item.domain);
        }
        return acc;
      }, {}))
      .sort(([a], [b]) => this.compareShadowDomains(a, b))
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

      // For CNAME traffic, group directly by the CNAME
      if (getByCname) {
        response.data.logs.forEach(item => {
          const groupBy = item['cname'];
          if (groupBy !== 'null') {
            if (!trafficData[groupBy]) {
              trafficData[groupBy] = [];
            }
            trafficData[groupBy].push(item);
          }
        });
      }

      // Iterate over domain traffic and handle shadow domains
      dataArray.forEach(item => {
        let domain = item.domain;

        // Check if the current domain is a shadow domain and find its parent domain
        const matchedExplicitShadow = shadowDomains[domain];
        const matchedWildcard = Object.keys(shadowDomains).find(wildcard => {
          const wildcardRegex = new RegExp(`^${wildcard.replace("*", ".*")}$`);
          return domain.match(wildcardRegex);
        });

        const parentDomain = shadowDomains[matchedWildcard] ||  matchedExplicitShadow;

        if (parentDomain) {
          // Merge with the parent domain if it's a shadow domain
          if (!trafficDomainData[parentDomain]) {
            trafficDomainData[parentDomain] = [];
          }
          const parentItemIndex = trafficDomainData[parentDomain].findIndex(entry => entry.domain === parentDomain && entry.second === item.second);
          if (parentItemIndex !== -1) {
            trafficDomainData[parentDomain][parentItemIndex].total_traffic += item.total_traffic;
            trafficDomainData[parentDomain][parentItemIndex].total_request += item.total_request;
          } else {
            trafficDomainData[parentDomain].push({
              domain: parentDomain,
              shadow: matchedExplicitShadow || matchedWildcard,
              total_traffic: item.total_traffic,
              total_request: item.total_request,
              second: item.second
            });
          }
        } else {
          // Create new entry if it's not a shadow domain

          // In some cases, the API might not contain main domain traffic but only shadow domain traffic
          // So, we add it as it is, but if the domain starts with '_.', we remove it and replace it with the main domain
          domain = domain.replace(/^_./, '');

          if (!trafficDomainData[domain]) {
            trafficDomainData[domain] = [];
          }
          trafficDomainData[domain].push({
            domain,
            shadow: item.shadow,
            total_traffic: item.total_traffic,
            total_request: item.total_request,
            second: item.second
          });
        }
      });

      this.trafficCnameData = trafficData;
      this.trafficDomainData = isPagedRequest && !getByCname ? { ...this.trafficDomainData, ...trafficDomainData} : trafficDomainData;

      if (!getByCname) {
        this.$refs.overviewDomain.$refs.tableTraffic.changeSort('total_traffic', 0);

        if (response.data.has_more) {
          await this.onApplyTimeRange(getByCname = null, page + 1);
        }
      }

      this.isLoading = false;
    },
    compareShadowDomains (a, b) {
      if (a.startsWith("*") && !b.startsWith("*")) {
        return -1;
      } else if (!a.startsWith("*") && b.startsWith("*")) {
        return 1;
      }
      return 0;
    },
    showPanel() {
      if (this.isLoading) {
        return;
      }

      if (this.timeRangePanel) {
        this.timeRangePanel.show();

        return;
      }

      const handleApply = async () => {
        if (this.timeRangePanel) {
          await this.timeRangePanel.hide();
        }

        if (this.isViewByCname) {
          // fetch by cname
          await this.onApplyTimeRange(this.userCname);
        } else {
          // fetch per domain to reflect update time range
          await this.onApplyTimeRange();
        }
      };

      this.timeRangePanel = this.$showPanel({
        component: 'app-time-range-picker',
        openOn: 'left',
        cssClass: 'timerange-slideout',
        width: 400,
        keepAlive: true,
        props: {
          onApply: handleApply,
          limited: false
        }
      });
    }
  }
}
</script>
